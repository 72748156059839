@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: SomarSans;
  src: url("/public/assets/fonts/SomarSans-ExtraBold.ttf");
  font-weight: 800;
}
body {
  margin: 0;
  font-family: SomarSans, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #363636;
  background-color: #fcfcfc;
}
.rtl {
  direction: rtl;
}
input,
textarea {
  outline: none;
  background-color: transparent;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

select {
  appearance: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #36363628;
  border-radius: 10px;
}
